































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { cashManagementServices } from "@/services/cashmanagement.service";
import moment from "moment";
import { settingsServices } from "@/services/settings.service";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { masterServices } from "@/services/master.service";
import {
  DataListBankTransfer,
  DataListCashOut,
} from "@/models/interface/cashManagement.interface";

@Component
export default class ListSettlement extends Vue {
  currentPage = 1 as number;
  limit = 10;
  page = 0;
  dataList = [] as DataListCashOut[];
  totalData = 0 as number;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "listSettlement" });
  downloadParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    settlementNo: "ALL",
    dateFrom: "ALL",
    dateTo: "ALL",
    bankSettled: "ALL",
    currency: "ALL",
  };
  dataSettlementNo = [] as any;
  searchData = "" as any;
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branch",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      scopedSlots: { customRender: "isDate" },
      width: 200,
    },
    {
      title: this.$t("lbl_settlement_no"),
      dataIndex: "documentNo",
      key: "settlementNo",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_bank_settled"),
      dataIndex: "bankName",
      key: "bankSettled",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      scopedSlots: { customRender: "isCurrency" },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ] as ColumnTableCustom;
  formRules = {
    dateFrom: {
      decorator: ["dateFrom"],
    },
    dateTo: {
      decorator: ["dateTo"],
    },
    branch: {
      decorator: ["branch"],
    },
    bankSettled: {
      decorator: ["bankSettled"],
    },
    settlementNo: {
      decorator: ["settlementNo"],
    },
    currency: {
      decorator: ["currency"],
    },
  };
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
  dataBranch = [] as DataWarehouseBranch[];
  dataBankSettled = [] as any[];
  settlementNo = [] as any[];
  dataCurrency = [] as DataMasterCurrency[];
  loading = {
    branch: false as boolean,
    table: false as boolean,
    find: false as boolean,
    settlementNo: false as boolean,
    currency: false as boolean,
    bankSettled: false as boolean,
  };
  searchDropdown(value, type) {
    if (value) {
      switch (type) {
        case "branch":
          this.getBranch(value);
          break;
        case "settlementNo":
          this.getListSettlementNumber(value);
          break;
        case "currency":
          this.getCurrency(value);
          break;
        case "bank":
          this.getListBankSettled(value);
          break;
        default:
          break;
      }
    }
  }
  getListSettlementNumber(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) params.search = `documentNo~*${value}*`;
    cashManagementServices.listSettlement(params).then(res => {
      this.dataSettlementNo = res.data.filter(item => item.documentNo);
    });
  }
  getListBankSettled(value) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      sort: `createdDate:desc`,
    };
    this.loading.bankSettled = true;
    if (value) {
      params.search = `bankAccName~*${value}*_OR_bankAccNumber~*${value}*_OR_bankName~*${value}*`;
    }
    masterServices
      .listCompanyBank(params)
      .then(res => {
        this.dataBankSettled = res.data;
      })
      .finally(() => {
        this.loading.bankSettled = false;
      });
  }
  getCurrency(value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (value)
      params.search = `currencyCode~*${value}*_OR_description~*${value}`;
    return settingsServices
      .listOfMasterCurrency(params, "")
      .then(res => {
        this.dataCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  responseViewTable(response) {
    this.$router.push(
      "/cash-management/transactions/settlement/edit/" + response.data.id
    );
  }
  findData(page): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        const params: RequestQueryParamsModel = {
          page: page ? this.page : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        for (const key in this.downloadParam) {
          if (key != "companyName") {
            this.downloadParam[key] = "ALL";
          }
        }
        if (this.dynamicSearch(value)) {
          this.searchData = this.dynamicSearch(value);
          params.search = this.dynamicSearch(value);
        }
        this.getData(params);
      }
    });
  }

  getData(params) {
    this.loading.table = true;
    cashManagementServices.listSettlement(params).then(res => {
      this.loading.table = false;
      this.dataList = res.data;
      this.totalData = res.totalElements;
    });
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParam.branch = this.dataBranch.find(item => item.id == value)
        ?.name as string;
      return operator + `branchWarehouse.secureId~${value}`;
    } else if (key === "currency" && value) {
      this.downloadParam.currency = this.dataCurrency.find(
        item => item.id == value
      )?.currencyCode as string;
      return operator + `currency.secureId~${value}`;
    } else if (key === "settlementNo" && value) {
      this.downloadParam.settlementNo = value;
      return operator + `documentNo~${value}`;
    } else if (key === "bankSettled" && value) {
      this.downloadParam.bankSettled =
        this.dataBankSettled.find(item => item.id === value).bankName +
        " - " +
        this.dataBankSettled.find(item => item.id === value).bankAccNumber;
      return operator + `companyBank.secureId~${value}`;
    } else if (key === "dateFrom" && value) {
      this.downloadParam.dateFrom = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transactionDateFrom>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}`
      );
    } else if (key === "dateTo" && value) {
      this.downloadParam.dateTo = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transactionDateTo<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    } else return "";
  }
  clearItems(): void {
    this.form.resetFields();
  }
  CreateNew(): void {
    this.$router.push("/cash-management/transactions/settlement/create");
  }
  download() {
    let params = {
      params: `${this.downloadParam.companyName},${this.downloadParam.branch},${this.downloadParam.settlementNo},${this.downloadParam.dateFrom},${this.downloadParam.dateTo},${this.downloadParam.bankSettled},${this.downloadParam.currency}`,
    } as RequestQueryParamsModel;
    if (this.searchData) {
      params.search = this.searchData;
    }
  }
  mounted() {
    this.getBranch("");
    this.getCurrency("");
    this.getListBankSettled("");
    this.getListSettlementNumber("");
  }

  getBranch(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  handleClickColumn(record, objColumnNameValue) {
    this.$router.push(
      "/generaljournal/journal/detail/" + record.journalNumberId
    );
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.currentPage = 1;
    this.findData(true);
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.currentPage = response.page;
    this.findData(true);
  }
}
